import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
// import { RestorePage } from '@material-ui/icons';
import { ActionButton, ActionMenu /* , IconButton */ } from '../../components';
import SpeedDialMenu from './SpeedDialMenu';

function FormControlPanel(props) {
  const { actions } = props;
  const [anchor, setAnchor] = React.useState(null);

  return (
    <span>
      <Hidden smUp>
        <SpeedDialMenu alwaysShowMenu actions={actions} />
      </Hidden>
      <Hidden smDown>
        <ActionButton actions={actions} setAnchor={setAnchor} pack="always" />
        <ActionMenu
          anchor={anchor}
          id="form-actions"
          actions={actions}
          onClose={() => setAnchor(null)}
        />
      </Hidden>
    </span>
  );
}

FormControlPanel.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      link: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default FormControlPanel;
