import { List } from 'immutable';
import { searchRegex } from '../../../core/util';

const Operators = {
  EQUALS: '=',
  DIFF: '<>',
  LIKE: '%',
  NOT_LIKE: '!%',
  GREATER_OR_EQUAL: '>=',
  LESS_OR_EQUAL: '<=',
};

const OperatorLabel = {
  [Operators.EQUALS]: '=',
  [Operators.DIFF]: '≠',
  [Operators.LIKE]: '≈',
  [Operators.NOT_LIKE]: '⊄',
  [Operators.GREATER_OR_EQUAL]: '≥',
  [Operators.LESS_OR_EQUAL]: '≤',
};

const OperatorTooltip = {
  [Operators.EQUALS]: 'igual à',
  [Operators.DIFF]: 'diferente de',
  [Operators.LIKE]: 'parecido/contém',
  [Operators.NOT_LIKE]: 'não contém',
  [Operators.GREATER_OR_EQUAL]: 'menor ou igual',
  [Operators.LESS_OR_EQUAL]: 'maior ou igual',
};

function getComparator(operator) {
  switch (operator) {
    case Operators.LIKE:
      return (like, s) => s.match(searchRegex(like, 'ig'));
    case Operators.NOT_LIKE:
      return (like, s) => !s.match(searchRegex(like, 'ig'));
    case Operators.DIFF:
      return (x, y) => x !== y;
    case Operators.LESS_OR_EQUAL:
      return (x, y) => y <= x;
    case Operators.GREATER_OR_EQUAL:
      return (x, y) => y >= x;
    default:
      return (x, y) => x === y;
  }
}

/**
 * Se o campo for um array transforma para string de acrodo com o `ZPC`.
 *
 * Exemplo:
 *  entrada: ['formando', 'nome']
 *  saida: 'formando.nome'
 *
 * @param {string|array} field
 */
function transformFieldName(field) {
  if (Array.isArray(field) || List.isList(field)) {
    return field.join('.');
  }
  return field;
}

/**
 * Executa a opreação reversa a `transformFieldName`.
 *
 * @param {string} field
 */
function untransformFieldName(field) {
  const splited = field.split('.');
  if (splited.length === 1) {
    return splited[0];
  }
  return splited;
}

export {
  Operators,
  getComparator,
  OperatorLabel,
  OperatorTooltip,
  transformFieldName,
  untransformFieldName,
};
