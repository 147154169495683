import { List } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import form from '../../../core/components/form/actions';
import qform from '../../../core/components/form/queries';
import storage from '../../../core/storage/actions';
import qstorage from '../../../core/storage/queries';
import FilterForm from './FilterForm';

function queryFn(data) {
  return data.get('filters') || List();
}

function mapStateToProps(state, ownProps) {
  const { view, id = 'filterForm', table = 'table' } = ownProps;
  return {
    formState: qform.queryData(state, view, id, queryFn),
    extraFilters: qstorage.get(state, view, [table, 'datefilter'], List()),
    rowsId: qstorage.get(state, view, [table, id, 'rowsId'], List([0])),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, id = 'filterForm', table = 'table' } = ownProps;
  const actions = {
    setRowsId: (quantity) => storage.set(view, [table, id, 'rowsId'], quantity),
    deleteRow: (index) => form.removeMultifieldRow(view, id, 'filters', index),
    save: (f) => storage.set(view, [table, id, 'submit'], f),
    setOnFilter: (fn) => storage.set(view, [table, id, 'onFilter'], fn),
    setFormState: (newState) => form.merge(view, id, newState),
    setField: (index, field, value) =>
      form.setFieldState(view, id, {
        multifield: 'filters',
        field,
        index,
        value,
      }),
  };
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
