import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import SessionStates from '../../../core/session/session-states';
import OpeningAnimation from './OpeningAnimation';
import LogoutDialog from './LogoutDialog';

function LoadingScreen(props) {
  const { sessionState } = props;
  const reverse = sessionState === SessionStates.SUSPENDED;
  const showAnimation = sessionState === SessionStates.ALIVE || reverse;

  return (
    <Grid container>
      <Grid item xs={12}>
        {!reverse && <LinearProgress />}
        {showAnimation && <OpeningAnimation reverse={reverse} />}
        {/* <OpeningAnimation reverse={reverse} /> */}
      </Grid>
      {sessionState === SessionStates.ENDED && <LogoutDialog />}
    </Grid>
  );
}

export default LoadingScreen;
