import React from 'react';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import {
  Slide,
  Snackbar,
  IconButton,
  SnackbarContent,
} from '@material-ui/core';
import qreport from '../../core/report/queries';
import report from '../../core/report/actions';

function styles(theme) {
  // @TODO Update: Material UI migration hotspot
  const u = theme.spacing(1);

  return {
    base: { margin: u },
    notice: { backgroundColor: green[600] },
    error: { backgroundColor: theme.palette.error.dark },
    info: { backgroundColor: theme.palette.primary.dark },
    warning: { backgroundColor: amber[700] },
  };
}

/**
 * Exibe as mensagens armazenadas pelo sistema mensagens
 * `core/report` em [Snackbars](https://material-ui.com/demos/snackbars/).
 *
 * @param {object} props
 */
function Notifier(props) {
  const { popMessage, message = null, classes } = props;

  if (!message) {
    return null;
  }
  return (
    <Snackbar
      onClose={popMessage}
      open={Boolean(message)}
      autoHideDuration={5000}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <SnackbarContent
        message={message.get('message')}
        className={classes[message.get('type')]}
        action={
          <IconButton aria-label="Fechar" color="inherit" onClick={popMessage}>
            <Close />
          </IconButton>
        }
      />
    </Snackbar>
  );
}

function mapStateToProps(state) {
  return { message: qreport.getMessages(state, 1).first() };
}

const mapDispatchToProps = { popMessage: report.popMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Notifier));
