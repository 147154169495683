import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { Section, SimpleFilterForm, Table } from '../../components';
import TableWrapper from '../_internal/TableWrapper';
import {
  getRemainingActions,
  incrementActions,
} from '../_internal/table-util/actions';

function TableTab(props) {
  const {
    id,
    view,
    title,
    preamble,
    epilogue,
    detailers,
    mainAction,
    tableProps,
    searchField,
    detailTarget,
    sectionProps,
    simpleFilterFormProps,
  } = props;

  //
  // Estado interno -------------------------------------------------
  //
  const [detailedTuple, setDetailedTuple] = React.useState(null);
  const [currentDetailer, setCurrentDetailer] = React.useState(null);
  //
  // Outras de props ------------------------------------------------
  //
  const withSection = Boolean(title || sectionProps);
  const actions = incrementActions({
    detailers,
    tableProps,
    setDetailedTuple,
    setCurrentDetailer,
  });

  return (
    <TableWrapper
      preamble={preamble}
      epilogue={epilogue}
      mainAction={mainAction}
      hide={tableProps.loading}
      detailProps={
        !currentDetailer || !detailedTuple
          ? null
          : {
              id,
              detailTarget,
              tuple: detailedTuple,
              open: Boolean(detailedTuple),
              currentAction: currentDetailer,
              onClose: () => setDetailedTuple(null),
              actions: getRemainingActions(currentDetailer, actions),
            }
      }
    >
      {withSection ? (
        <Section title={title} dense headerGutter {...sectionProps}>
          {simpleFilterFormProps && (
            <Grid item xs={12}>
              <SimpleFilterForm
                id={`${id}/SimpleFilterForm`}
                view={view}
                {...simpleFilterFormProps}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Table view={view} {...tableProps} actions={actions} />
          </Grid>
        </Section>
      ) : (
        <Grid container>
          {simpleFilterFormProps && (
            <Grid item xs={12}>
              <SimpleFilterForm
                id={`${id}/SimpleFilterForm`}
                view={view}
                {...simpleFilterFormProps}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper>
              <Table
                view={view}
                {...tableProps}
                actions={actions}
                stringfyTuple={searchField && searchField.stringfyTuple}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </TableWrapper>
  );
}

TableTab.defaultProps = {
  detailTarget: 'drawer',
  sectionProps: null,
  searchField: null,
  mainAction: null,
  epilogue: null,
  preamble: null,
  detailers: [],
  title: null,
};

TableTab.propTypes = {
  id: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  title: PropTypes.string,
  preamble: PropTypes.func,
  epilogue: PropTypes.func,
  detailers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      reference: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    }),
  ),
  searchField: PropTypes.shape({
    view: PropTypes.string.isRequired,
    table: PropTypes.string,
    stringfyTuple: PropTypes.func.isRequired,
  }),
  tableProps: PropTypes.shape({
    ...Table.propTypes,
    view: PropTypes.string,
  }).isRequired,
  /*
   * Ação que será executada em um float button, geralmente será
   * usado para redirecionar para um formulário
   */
  mainAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func,
      link: PropTypes.string,
    }),
  ]),
  detailTarget: PropTypes.oneOf(['drawer', 'dialog']),
  sectionProps: PropTypes.shape(Section.propTypes),
};

export default TableTab;
