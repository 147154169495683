import React from 'react';
import { List, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import GridInput from '../../../components/form/GridInput';
import { OperatorLabel, Operators } from './common-utils';

function styles() {
  return {
    operator: {
      textAlign: 'center',
      paddingRight: 0,
    },
    underline: {
      '&&&:hover': {
        borderBottom: 'none',
      },

      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  };
}

function selectOperators(type) {
  const {
    LIKE,
    DIFF,
    EQUALS,
    NOT_LIKE,
    LESS_OR_EQUAL,
    GREATER_OR_EQUAL,
  } = Operators;

  switch (type) {
    case 'currency':
    case 'counter':
    case 'number':
      return [GREATER_OR_EQUAL, LESS_OR_EQUAL, EQUALS, DIFF];
    case 'text':
      return [LIKE, NOT_LIKE, EQUALS, DIFF];
    case 'date':
    case 'datetime':
      return [GREATER_OR_EQUAL, LESS_OR_EQUAL];
    default:
      return [EQUALS, DIFF];
  }
}

function getOperatorsList(type) {
  const operators = selectOperators(type);
  return List(
    operators.map((op) => Map({ value: op, label: OperatorLabel[op] })),
  );
}

function getFilterValueFieldIcon(params) {
  const { index, rowsId, addRow, removeRow } = params;
  const isLast = index === rowsId.size - 1;
  if (isLast) {
    return {
      icon: <AddIcon />,
      tooltip: 'Adicionar filtro',
      onClick: addRow,
    };
  }
  return {
    icon: <DeleteIcon />,
    tooltip: 'Remover filtro',
    onClick: () => removeRow(index),
  };
}

function getType(type, options) {
  if (!type) {
    return options ? 'select' : 'text';
  }
  return type;
}

function renderValueField(filter, params) {
  const { index } = params;
  const action = getFilterValueFieldIcon(params);
  const filterJS = filter.toJS();
  const options = filter.get('options');
  const {
    label,
    field,
    reload,
    condition,
    view,
    type: originalType,
    options: originalOptions,
    ...others
  } = filterJS;
  const type = getType(originalType, originalOptions);
  return (
    <GridInput
      name="value"
      index={index}
      action={action}
      options={options}
      type={type}
      autoComplete="off"
      placeholder="Valor..."
      multifield="filters"
      {...others}
      sm={5}
    />
  );
}

function noIcon() {
  return null;
}

function FilterRow(props) {
  const {
    index,
    addRow,
    rowsId,
    setField,
    removeRow,
    fieldsList,
    getFilter,
    currentFilter,
    classes,
  } = props;
  const operatorsList = getOperatorsList(currentFilter.get('type'));

  return (
    <Grid container spacing={0}>
      <GridInput
        name="field"
        label="Tipo"
        type="select"
        multifield="filters"
        options={fieldsList}
        onChange={(e) => {
          // Atualiza os campos de operador e valor.
          const newFieldId = e.target.value;
          if (newFieldId !== currentFilter.get('field')) {
            const newFilter = getFilter(e.target.value);
            const newOperatorsList = getOperatorsList(newFilter.get('type'));
            setField(index, 'value', null);
            setField(index, 'operator', newOperatorsList.getIn([0, 'value']));
          }
        }}
        index={index}
        sm={5}
      />
      <GridInput
        label=" "
        name="operator"
        type="select"
        index={index}
        minWidth="35px"
        multifield="filters"
        options={operatorsList}
        defaultValue={operatorsList.getIn([0, 'value'])}
        InputProps={{
          disableUnderline: true,
        }}
        SelectProps={{
          IconComponent: noIcon,
          classes: { selectMenu: classes.operator },
        }}
        xs={2}
        sm={1}
      />
      {renderValueField(currentFilter, { index, rowsId, removeRow, addRow })}
    </Grid>
  );
}

export default withStyles(styles)(FilterRow);
