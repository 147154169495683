/**
 * Author: João Lucas, 04/2020
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Hidden, Grid, LinearProgress } from '@material-ui/core';
import ContentPanel from './ContentPanel';
import Header from './Header';

function styles(theme) {
  const mdDown = theme.breakpoints.down('md');
  const lgUp = theme.breakpoints.up('lg');
  const xl = theme.breakpoints.only('xl');
  const lg = theme.breakpoints.only('lg');
  const u = theme.spacing(1);
  const lgHeight = u * 110;
  const topHeight = u * 10;

  return {
    container: {
      backgroundColor: '#F6F6F6',
    },
    left: {
      display: 'flex',
      height: topHeight,
      [mdDown]: {
        height: 180,
      },
      [lgUp]: {
        backgroundColor: '#25B0D3',
        justifyContent: 'center',
        alignItems: 'center',
        height: lgHeight,
      },
      [xl]: {
        height: '100vh',
      },
      [lg]: {
        height: '130vh',
      },
    },
    right: {
      [mdDown]: {
        backgroundColor: '#25B0D3',
        paddingBottom: 4 * u,
      },
    },
  };
}

function SplitView(props) {
  const { classes, loading, contextImage, children } = props;
  const { xs = 12, lg = 12, width, alt, src } = contextImage;
  const body = <ContentPanel>{children}</ContentPanel>;

  return (
    <div>
      {loading && <LinearProgress />}
      <Grid container className={classes.container}>
        <Grid item xs={xs} lg={lg} className={classes.left}>
          <Hidden lgDown>
            <img alt={alt} src={src} width={width} />
          </Hidden>
          <Hidden lgUp>
            <Header />
          </Hidden>
        </Grid>
        {children && (
          <Grid item xs={12} lg={6} className={classes.right}>
            <Hidden lgUp>{body}</Hidden>
          </Grid>
        )}
      </Grid>
      {children && <Hidden mdDown>{body}</Hidden>}
    </div>
  );
}

SplitView.defaultProps = {
  children: null,
};

SplitView.propTypes = {
  // Mostra a barra de carregamento
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  contextImage: PropTypes.shape({
    xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  // Props injetadas
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(SplitView);
