import React from 'react';
import widthWidth from '@material-ui/core/withWidth';
import DialogDetail from './DialogDetail';
import DrawerDetail from './DrawerDetail';

/**
 * Ajusta os callbacks de `onClick` das ações
 * visíveis no detalhamento para que recebam,
 * além do evento de click, a tupla na qual
 * deve-se aplicar a ação.
 */
function fixActions(props, tuple) {
  const { actions } = props;
  if (!actions || !tuple) {
    return actions;
  }

  const createOnClick = (action) => (event) => {
    const { onClick } = action;
    if (onClick) {
      onClick(event, tuple);
    }
  };
  const newActions = [];

  for (let i = 0; i < actions.length; i += 1) {
    const a = actions[i];
    newActions.push({ ...a, onClick: createOnClick(a) });
  }
  return newActions;
}

/**
 * Componente que exibe os detalhes de uma entrada
 * da tabela de uma `DataTableView` em um drawer --
 * em telas grandes -- e em um popover fullscreen
 * em telas pequenas.
 */
function Detail(props) {
  const {
    id,
    open,
    width,
    tuple,
    onClose,
    detailTarget,
    currentAction,
  } = props;
  // children,
  const showDialog =
    width === 'xs' || width === 'sm' || detailTarget === 'dialog';
  const Root = showDialog ? DialogDetail : DrawerDetail;
  const { component: Component } = currentAction;

  return (
    <Root
      id={id}
      open={open}
      tuple={tuple}
      onClose={onClose}
      actions={fixActions(props, tuple)}
      title={currentAction.title || currentAction.reference}
    >
      {tuple && <Component {...currentAction} tuple={tuple} />}
    </Root>
  );
}

export default widthWidth()(Detail);
