import React from 'react';
import FormTab from './FormTab';
import TableTab from './TableTab';

function createTableTabRenderer(id, tabProps, view, index) {
  const { title, tab, view: tabView } = tabProps;
  const key = `${tab}:${title || ''}:${index}`;

  return () => (
    <TableTab key={key} id={key} view={tabView || view} {...tabProps} />
  );
}

function createFormTabRenderer(id, props, view, index) {
  const { tab, title, render, view: tabView, ...rest } = props;
  const key = `${tab}:${title}:${index}`;

  return () => (
    <FormTab key={key} id={key} view={tabView || view} {...rest}>
      {render()}
    </FormTab>
  );
}

function renderTab(id, tab, view, index) {
  const { render, formProps, tableProps } = tab;

  if (formProps) {
    return createFormTabRenderer(id, tab, view, index);
  }
  if (tableProps) {
    return createTableTabRenderer(id, tab, view, index);
  }
  return () => <div key={`${tab.tab}:${index}:blank`}>{render()}</div>;
}

export default renderTab;
