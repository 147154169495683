import React from 'react';
import { Link } from 'react-router-dom';
import { BugReport, MoreVert } from '@material-ui/icons';
import {
  Menu,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import IconButton from '../../../../components/IconButton';

function getLinkProps(tuple, link, href) {
  if (link) {
    return {
      component: Link,
      to: typeof link === 'function' && tuple ? link(tuple) : link,
    };
  }
  if (href) {
    return {
      component: 'a',
      href,
    };
  }
  return null;
}

function DrawerAction(props) {
  const { tuple, actions } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  if (!actions || actions.length < 1) {
    return null;
  }
  if (actions.length < 2) {
    const {
      icon,
      link,
      href,
      color,
      disabled,
      beforeTitle,
      ...rest
    } = actions[0];
    const linkProps = getLinkProps(tuple, link, href);

    let isDisabled = false;
    if (tuple) {
      isDisabled = typeof disabled === 'function' ? disabled(tuple) : disabled;
    }
    return (
      <IconButton
        color="inherit"
        disabled={isDisabled}
        {...linkProps}
        {...rest}
      >
        {icon}
      </IconButton>
    );
  }
  return (
    <>
      <IconButton
        tooltip="Ações"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="DataTableViewAction"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {actions.map((action) => {
          const {
            link,
            href,
            tooltip,
            onClick,
            icon = <BugReport />,
            disabled,
          } = action;
          const isDisabled =
            typeof disabled === 'function' ? disabled(tuple) : disabled;
          const linkProps = getLinkProps(tuple, link, href);

          return (
            <MenuItem
              key={tooltip}
              onClick={(e) => {
                if (onClick) {
                  onClick(e, tuple);
                }
                setAnchorEl(null);
              }}
              disabled={isDisabled}
              {...linkProps}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                inset
                primary={
                  <Typography style={{ fontSize: '1rem' }}>
                    {tooltip}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default DrawerAction;
