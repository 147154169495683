import PropTypes from "prop-types";
import React from "react";

import { DateFilter, Tabbed, Table } from "../../components";
import { some } from "../../core/util";
import Base from "../Base";
import MainActionButton from "../_internal/MainActionButton";
import FormTab from "./FormTab";
import renderTab from "./render-tab";

function handleChangeTableTab(tabIdx, tabs) {
  const { onReload } = tabs[tabIdx];

  if (onReload) {
    onReload();
  }
}

function handleChangeFormTab(tabIdx, tabs) {
  if (!some(tabIdx)) {
    return;
  }

  const { clean } = tabs[tabIdx];
  if (clean) {
    clean();
  }
}

function TabbedView(props) {
  const {
    id,
    tabs,
    view,
    title,
    subtitle,
    mainAction,
    denyAccess,
    actionPanel,
    onChangeTab,
    filterProps,
    controlPanel,
    returnButton,
    hideReturnButton,
    dateFilterProps: dfp,
    dropdown,
  } = props;

  const [lastTab, setLastTab] = React.useState(0);
  const [currentMainAction, SetCurrentMainAction] = React.useState(mainAction);

  React.useEffect(() => {
    if (!mainAction && tabs[lastTab].mainAction) {
      SetCurrentMainAction(tabs[lastTab].mainAction);
    } else {
      SetCurrentMainAction(mainAction);
    }
  }, [lastTab]);

  const baseProps = React.useMemo(() => {
    const currentTabProps = tabs[lastTab];
    return currentTabProps && currentTabProps.baseProps;
  }, [lastTab]);

  return (
    <Base
      title={title}
      subtitle={subtitle}
      denyAccess={denyAccess}
      actionPanel={actionPanel}
      filterProps={filterProps}
      controlPanel={controlPanel}
      returnButton={returnButton}
      hideReturnButton={hideReturnButton}
      searchField={tabs[lastTab] && tabs[lastTab].searchField}
      {...baseProps}
    >
      {dfp && (
        <div
          style={{
            padding: 16,
            width: "100%",
            display: "flex",
            paddingBottom: 16,
            justifyContent: "center",
          }}
        >
          <DateFilter {...dfp} />
        </div>
      )}
      <Tabbed
        id={`${id}-tabbed`}
        view={view}
        labels={tabs.map(({ tab }) => tab)}
        icons={tabs.map(({ icon }) => icon)}
        dropdown={dropdown}
        SetCurrentMainAction={SetCurrentMainAction}
        onChange={(tabIdx) => {
          handleChangeTableTab(tabIdx, tabs);
          handleChangeFormTab(lastTab, tabs);
          if (onChangeTab) {
            onChangeTab(tabIdx);
          }
          setLastTab(tabIdx);
        }}
        content={tabs.map((tab, index) => renderTab(id, tab, view, index))}
      />
      {MainActionButton.renderDefault(currentMainAction)}
    </Base>
  );
}

TabbedView.defaultProps = {
  id: "@!TabbedView",
  subtitle: null,
  mainAction: null,
  onChangeTab: null,
  controlPanel: null,
  dateFilterProps: null,
  actionPanel: null,
  filterProps: null,
  denyAccess: false,
};

TabbedView.propTypes = {
  id: PropTypes.string,
  view: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  denyAccess: PropTypes.bool,
  actionPanel: PropTypes.element,
  controlPanel: PropTypes.element,
  onChangeTab: PropTypes.func,
  filterProps: PropTypes.shape({
    id: PropTypes.string,
    validator: PropTypes.func,
    table: PropTypes.string,
    view: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        condition: PropTypes.func,
        reload: PropTypes.bool,
      }),
    ).isRequired,
  }),
  dateFilterProps: PropTypes.shape({
    view: PropTypes.string,
    onFilter: PropTypes.func,
    dateField: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        startField: PropTypes.string.isRequired,
        endField: PropTypes.string.isRequired,
      }),
    ]),
    defaultRange: PropTypes.oneOf(["7days", "5days", "30days"]),
    defaultTab: PropTypes.oneOf(["daily", "monthly", "periodically", "last"]),
  }),
  mainAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func,
      link: PropTypes.string,
    }),
  ]),
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        view: PropTypes.string,
        tab: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        icon: PropTypes.any,
        render: PropTypes.func.isRequired,
        mainAction: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.shape({
            icon: PropTypes.element.isRequired,
            onClick: PropTypes.func,
            link: PropTypes.string,
          }),
        ]),
        ...FormTab.propTypes,
      }),
      PropTypes.shape({
        view: PropTypes.string,
        title: PropTypes.string,
        tab: PropTypes.string.isRequired,
        icon: PropTypes.instanceOf(React.element),
        onReload: PropTypes.func.isRequired,
        detailTarget: PropTypes.oneOf(["drawer", "dialog"]),
        searchField: PropTypes.shape({
          view: PropTypes.string.isRequired,
          table: PropTypes.string,
          stringfyTuple: PropTypes.func.isRequired,
        }),
        tableProps: PropTypes.shape(Table.propTypes).isRequired,
        detailers: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            reference: PropTypes.string.isRequired,
            component: PropTypes.oneOfType([PropTypes.instanceOf(React.Component), PropTypes.func])
              .isRequired,
          }),
        ),
        mainAction: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.shape({
            icon: PropTypes.element.isRequired,
            onClick: PropTypes.func,
            link: PropTypes.string,
          }),
        ]),
      }),
      PropTypes.shape({
        view: PropTypes.string,
        tab: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        baseProps: PropTypes.object,
        mainAction: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.func,
          PropTypes.shape({
            icon: PropTypes.element.isRequired,
            onClick: PropTypes.func,
            link: PropTypes.string,
          }),
        ]),
      }),
    ]),
  ).isRequired,
};

export default TabbedView;
