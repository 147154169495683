/**
 * Author: João Lucas, 04/2020
 */
import React from 'react';
import { Hidden, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Header from './Header';

function styles(theme) {
  const mdDown = theme.breakpoints.down('md');
  const xlUp = theme.breakpoints.up('xl');
  const lgUp = theme.breakpoints.up('lg');
  const lg = theme.breakpoints.only('lg');
  const u = theme.spacing(1);

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [mdDown]: {
        top: -7 * u,
        position: 'relative',
        marginRight: 1.5 * u,
        marginLeft: 1.5 * u,
      },
      [lgUp]: {
        top: 0,
        left: 0,
        width: '99vw',
        position: 'absolute',
      },
      [lg]: {
        height: '130vh',
      },
      [xlUp]: {
        height: '100vh',
      },
    },
    wrapper: {
      [lgUp]: {
        position: 'relative',
        minWidth: 90 * u,
        maxWidth: 110 * u,
      },
      [lg]: {
        left: 0,
      },
      [xlUp]: {
        left: 42.5 * u,
      },
    },
    paper: {
      padding: u,
      [lgUp]: {
        paddingTop: 2 * u,
        paddingBottom: 3 * u,
        paddingRight: 8 * u,
        paddingLeft: 5 * u,
      },
    },
  };
}

function ContentPanel(props) {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Hidden mdDown>
            <Header />
          </Hidden>
          {children}
        </Paper>
      </div>
    </div>
  );
}

const StyledContentPanel = withStyles(styles)(ContentPanel);

export default StyledContentPanel;
