import React from 'react';
import { AppBar, Drawer, Toolbar, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton } from '../../../../components';
import DrawerAction from './DrawerAction';

function DrawerDetail(props) {
  const { open, onClose, title, tuple, actions, children } = props;

  return (
    <Drawer open={open} anchor="bottom" onClose={onClose}>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {title}
            <DrawerAction actions={actions} tuple={tuple} />
          </Typography>
          <IconButton
            tooltip="Fechar"
            onClick={onClose}
            style={{ float: 'right' }}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        {children}
      </div>
    </Drawer>
  );
}

export default DrawerDetail;
