import { untransformFieldName, getComparator } from './common-utils';

/**
 * Condição padrão de filtragem para predicados.
 *
 * @param {Map} item
 * @param {Map} filter
 * @param {any} value
 * @return {boolean}
 */
function defaultCondition(item, filter, op, value) {
  const field = untransformFieldName(filter.get('field'));
  const comparator = getComparator(op);
  if (Array.isArray(field)) {
    return comparator(value, item.getIn(field, null));
  }
  return comparator(value, item.get(field, null));
}

/**
 * Separa e transforma os filtros que serão processados no front.
 *
 * @param {List} formState
 * @param {List} filters
 * @return {List}
 */
function getPredicates(formState, filters) {
  // Remove os filtros que não serão predicados.
  const filterToPredicate = filters.filter((el) => el && !el.get('reload'));
  const cleanFormState = formState.filter((tuple) =>
    Boolean(
      filterToPredicate.find((f) => f.get('field') === tuple.get('field')),
    ),
  );

  // Transforma os filtros que serão predicados.
  return cleanFormState.map((tuple) => {
    const field = tuple.get('field');
    const currentFilter = filterToPredicate.find(
      (f) => f.get('field') === field,
    );
    if (!currentFilter) {
      return null;
    }

    const value = tuple.get('value') === '@null' ? null : tuple.get('value');
    const op = tuple.get('operator');
    return tuple.set(
      'condition',
      currentFilter.get('condition') ||
        ((item) => defaultCondition(item, currentFilter, op, value)),
    );
  });
}

export default getPredicates;
