import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { Close, Menu as MenuIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import BashboardContext from '../../components/Dashboard/DasboardContext';
import { createSetObject } from '../../core/util';
import qcomm from '../../core/comm/queries';

function styles(theme) {
  const { h6 } = theme.typography;
  const createT = theme.transitions.create;
  const sharpT = theme.transitions.easing.sharp;
  const leaveT = theme.transitions.duration.leavingScreen;
  const enterT = theme.transitions.duration.enteringScreen;
  const { light: primary } = theme.palette.primary;

  return {
    titleWrapper: {
      flexGrow: 1,
      transition: createT('width', {
        duration: enterT,
        easing: sharpT,
      }),
    },
    titleWrapperClosed: {
      width: 0,
      display: 'none',
      transition: createT('width', {
        duration: leaveT,
        easing: sharpT,
      }),
    },
    titleSmall: {
      fontSize: h6.fontSize,
    },
    titleTiny: {
      fontSize: '0.8rem',
    },
    appBar: {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    },
    bar1Indeterminate: {
      backgroundColor: primary,
    },
    bar2Indeterminate: {
      backgroundColor: primary,
    },
  };
}

const smallSizes = createSetObject('xs', 'sm', 'md');

function titleClassName(width, title, classes) {
  if (width !== 'xs' || title.length <= 12) {
    return '';
  }
  if (title.length > 16) {
    return classes.titleTiny;
  }
  return classes.titleSmall;
}

function ViewAppBar(props) {
  const {
    title,
    width,
    history,
    classes,
    className,
    hideTitle,
    actionPanel,
    isUploading,
    closeButton,
    isDownloading,
    hideToggleMenuButton,
  } = props;
  const { toggleDrawer } = React.useContext(BashboardContext);

  return (
    <>
      <AppBar
        elevation={0}
        color="primary"
        position="static"
        className={classNames(classes.appBar, className)}
      >
        <Toolbar disableGutters={smallSizes[width]}>
          {!hideToggleMenuButton && (
            <span style={{ paddingRight: 4 }}>
              <IconButton color="inherit" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </span>
          )}
          <span
            className={
              !hideTitle ? classes.titleWrapper : classes.titleWrapperClosed
            }
          >
            <Typography
              variant="h5"
              color="inherit"
              style={{ paddingLeft: hideToggleMenuButton ? 16 : 0 }}
              classes={{ h5: titleClassName(width, title, classes) }}
            >
              <b>{title.toUpperCase()}</b>
            </Typography>
          </span>
          {closeButton && (
            <span style={{ paddingRight: 4 }}>
              <IconButton color="inherit" onClick={(e) => history.goBack()}>
                <Close />
              </IconButton>
            </span>
          )}
          {actionPanel}
        </Toolbar>
      </AppBar>
      {isDownloading || isUploading ? (
        <LinearProgress
          classes={{
            bar1Indeterminate: classes.bar1Indeterminate,
            bar2Indeterminate: classes.bar2Indeterminate,
          }}
        />
      ) : (
        <div style={{ height: 4 }} />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    isDownloading: qcomm.isLoading(state),
    isUploading: qcomm.isSending(state),
  };
}

export default connect(
  mapStateToProps,
  null,
)(withRouter(withStyles(styles)(withWidth()(ViewAppBar))));
