import React from 'react';
import PropTypes from 'prop-types';
import AccordionForm from '../components/AccordionForm';
import Base from './Base';

/**
 * Template que exibe acima do componente `Form` um
 * cabeçalho com (da esquerda para a direita) botão
 * de retornar à página anterior, título, subtítulo
 * -- abaixo do anterior --, e botões de ação.
 * Por padrão, o único botão de ação é o de limpar o
 * formulário, podendo ser definidos outros (TODO).
 * As ações em telas para desktop são renderizados
 * do lado direto do cabeçalho. Já em telas de mobile,
 * são dispostos num `SpeedDialMenu`.
 */
function AccordionFormView(props) {
  const {
    classes,
    title,
    subtitle,
    denyAccess,
    controlPanel,
    returnButton,
    hideReturnButton,
    ...otherProps
  } = props;

  return (
    <Base
      title={title}
      subtitle={subtitle}
      denyAccess={denyAccess}
      controlPanel={controlPanel}
      returnButton={returnButton}
      hideReturnButton={hideReturnButton}
    >
      <AccordionForm {...otherProps} />
    </Base>
  );
}

AccordionFormView.defaultProps = {
  subtitle: null,
  denyAccess: false,
  controlPanel: null,
  returnButton: null,
  hideReturnButton: false,
};

AccordionFormView.propTypes = {
  ...AccordionForm.propTypes,
  /**
   * Elementos que ficam a direita do subtitulo titulo.
   */
  controlPanel: PropTypes.element,
  /**
   * Se `true`, o botão de retornar, que ficar à
   * esquerda do título da página, não será exibido.
   */
  hideReturnButton: PropTypes.bool,

  /*
   * É passado como parâmetro em history.push,
   * redirecionando o usuário para outra view
   */
  returnButton: PropTypes.string,
  /**
   * Título da página. Em geral o título da página
   * deve ser um nome que identifique o objeto sendo
   * editado, caso haja algum.
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtítulo da página. Em geral ele deve descrever
   * o "tipo" de dado sendo editado ou criado no
   * formulário, como "Cadastro de conta bancária"
   * ou "Cadastro de formando".
   */
  subtitle: PropTypes.string,
  /**
   * Controle de permissão para acesso ao template.
   */
  denyAccess: PropTypes.bool,
};

export default AccordionFormView;
