import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Submit } from '../../components/form';
import {
  Form,
  AccordionForm,
  SectionForm,
  ConfirmationDialog,
} from '../../components';
import SubmitButton from '../../components/form/SubmitButton';

function getFormComponent(kind) {
  if (kind === 'expandable') {
    return AccordionForm;
  }
  if (kind === 'section') {
    return SectionForm;
  }
  return Form;
}

function FormTab(props) {
  const {
    view,
    kind,
    submit,
    doSubmit,
    children,
    formProps,
    verification,
    communicating,
    hideSubmitButton,
  } = props;
  const [open, setOpen] = React.useState(false);
  const doOpen = () => setOpen(true);
  const FormComponent = getFormComponent(kind);
  const invalidaCommunicating =
    communicating === undefined || communicating === null;

  if (kind === 'regular' && invalidaCommunicating) {
    throw new Error('Quando kind === "regular, communicating é obrigatório');
  }

  return (
    <FormComponent {...formProps} view={view}>
      {children}
      {!hideSubmitButton && (
        <div style={{ width: '100%' }}>
          <SubmitButton
            disabled={communicating}
            onClick={verification ? doOpen : doSubmit}
          >
            {submit.buttonText}
          </SubmitButton>
        </div>
      )}
      {verification && (
        <ConfirmationDialog
          open={open}
          onYes={doSubmit}
          {...verification}
          onClose={() => setOpen(false)}
          id={`@!$${view}:confirmation-dialog$!@`}
        />
      )}
    </FormComponent>
  );
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, submit, formProps, invalidateCache } = ownProps;
  if (!submit) {
    return {};
  }

  const { id: formID = 'form' } = formProps;
  const s = submit
    .setIn(['request', 'view'], view)
    .set('invalidateCache', invalidateCache);
  const doSubmit = Form.createSubmitFn(formID, s);
  return bindActionCreators({ doSubmit }, dispatch);
}

FormTab.defaultProps = {
  kind: 'regular',
  submit: Submit(),
  verification: null,
  communicating: null,
  invalidateCache: null,
  hideSubmitButton: false,
};

FormTab.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  kind: PropTypes.oneOf(['regular', 'expandable', 'section']),
  view: PropTypes.string.isRequired,
  verification: PropTypes.shape({
    type: PropTypes.oneOf(['warning', 'info']),
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    yes: PropTypes.string,
    no: PropTypes.string,
  }),
  communicating: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  invalidateCache: PropTypes.func,
  submit: PropTypes.instanceOf(Submit),
  formProps: PropTypes.oneOfType([
    PropTypes.shape(AccordionForm.propTypes),
    PropTypes.shape(Form.propTypes),
  ]).isRequired,
};

export default connect(null, mapDispatchToProps)(FormTab);
