import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SpeedDial from '@material-ui/lab/SpeedDial';
import { withStyles } from '@material-ui/core/styles';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { colors as themeColors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    primary: {
      backgroundColor: themeColors.primary,
      '&:hover': {
        backgroundColor: themeColors.primaryDark,
      },
    },
    secondary: {
      backgroundColor: themeColors.secondary,
      '&:hover': {
        backgroundColor: themeColors.secondaryDark,
      },
    },
    success: {
      backgroundColor: themeColors.success,
      '&:hover': {
        backgroundColor: themeColors.successDark,
      },
    },
    warning: {
      backgroundColor: themeColors.warning,
      '&:hover': {
        backgroundColor: themeColors.warningDark,
      },
    },
    fab: {
      position: 'fixed',
      bottom: u * 2,
      right: u * 2,
    },
  };
}

function fixProps({ link, href, color }, classes) {
  const isCustomColor =
    color === 'primary' ||
    color === 'secondary' ||
    color === 'success' ||
    color === 'warning';

  let linkProps = null;
  if (link) {
    linkProps = { component: Link, to: link };
  } else if (href) {
    linkProps = { component: 'a', href };
  }

  let colorProps = { color };
  if (isCustomColor) {
    colorProps = {
      color: 'primary',
      classes: { primary: classes[color] },
    };
  }

  return [linkProps, colorProps];
}

function SpeedDialMenu(props) {
  const { actions, alwaysShowMenu, classes } = props;
  const [open, setOpen] = React.useState(false);

  if (actions.length < 1) {
    return null;
  }
  if (actions.length < 2 && !alwaysShowMenu) {
    const action = actions[0];
    const { tooltip, icon, onClick } = action;
    const [linkProps, colorProps] = fixProps(action, classes);

    return (
      <Fab
        className={classes.fab}
        aria-label={tooltip}
        onClick={onClick}
        variant="round"
        {...colorProps}
        {...linkProps}
      >
        {icon}
      </Fab>
    );
  }

  const handleClick = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <SpeedDial
      className={classes.fab}
      ariaLabel="SpeedDial example"
      icon={<MoreVert />}
      onBlur={handleClose}
      onClick={handleClick}
      onClose={handleClose}
      onFocus={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      direction="up"
      open={open}
    >
      {actions.map((action) => {
        const { tooltip, icon, onClick } = action;
        const [linkProps] = fixProps(action, classes);
        return (
          <SpeedDialAction
            key={tooltip}
            icon={icon}
            tooltipTitle={tooltip}
            onClick={onClick}
            {...linkProps}
          />
        );
      })}
    </SpeedDial>
  );
}

SpeedDialMenu.defaultProps = {
  alwaysShowMenu: false,
};

const actionPropTypes = PropTypes.shape({
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  link: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
});

SpeedDialMenu.propTypes = {
  alwaysShowMenu: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.arrayOf(actionPropTypes).isRequired,
};

export default withStyles(styles)(SpeedDialMenu);
