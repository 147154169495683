import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import handshake from '../../core/session/handshake/actions';
import SessionStates from '../../core/session/session-states';
import Dashboard from '../../components/Dashboard';
import qsession from '../../core/session/queries';
import qcore from '../../core/queries';
import LoadingScreen from './LoadingScreen';

function RootComponent(props) {
  const {
    init,
    token,
    applet,
    profile,
    isPublic,
    children,
    location,
    sessionState,
  } = props;
  const menu = profile && profile.get('menu');
  const config = profile && profile.get('parametros');
  const keepLoading =
    !config ||
    (!isPublic && (!profile || !token)) ||
    sessionState === SessionStates.SUSPENDED;

  React.useEffect(() => {
    init();
  }, []);

  if (keepLoading) {
    return <LoadingScreen sessionState={sessionState} />;
  }
  if (isPublic || !menu) {
    return <>{children}</>;
  }
  if (!profile || !token) {
    return <LoadingScreen />;
  }
  return (
    <Dashboard menu={menu} location={location} applet={applet}>
      {children}
    </Dashboard>
  );
}

function mapDispatchToProps(dispatch, ownProps) {
  const { isPublic, history } = ownProps;
  if (isPublic) {
    return bindActionCreators({ init: handshake.initPublic }, dispatch);
  }
  return bindActionCreators(
    { init: () => handshake.init(history.push) },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {
    sessionState: qsession.getSessionState(state),
    profile: qsession.getUserProfile(state),
    config: qsession.getSystemParams(state),
    token: qsession.getToken(state),
    applet: qcore.getCurrentApplet(state),
  };
}

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RootComponent)),
);
