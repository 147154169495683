/**
 * Retorna um conjuto de ações semelhante ao
 * passado à `DataTable`, porém excluindo-se
 * a ação escolhida. O retorno desta função
 * é utilizado para renderizar as ações
 * no `Drawer`.
 */
function getRemainingActions(currentDetailer, actions) {
  return actions && currentDetailer
    ? actions.filter((a) => a && a.tooltip !== currentDetailer.reference)
    : null;
}

function detailerSet(detailers) {
  const refs = {};
  for (let i = 0; i < detailers.length; i++) {
    const d = detailers[i];
    refs[d.reference] = d;
  }
  return refs;
}

function bindDetailer(params) {
  const { detailers, setDetailedTuple, setCurrentDetailer } = params;
  const refs = detailerSet(detailers);

  return (action) => {
    const { tooltip, onClick } = action;
    const r = refs[tooltip];
    if (!r) {
      return action;
    }

    return {
      ...action,
      onClick: (event, tuple, rest) => {
        if (onClick) {
          onClick(event, tuple, rest);
        }
        setDetailedTuple(tuple);
        setCurrentDetailer(r);
      },
    };
  };
}

/**
 * Adiciona ação de abrir o Drawer no `onClick` das action
 * descritas na propriedade `useDrawer`.
 */
function incrementActions(params) {
  const {
    detailers,
    tableProps,
    setDetailedTuple,
    setCurrentDetailer,
  } = params;
  const { actions } = tableProps;

  if (!detailers || detailers.length < 1 || !actions || actions.length < 1) {
    return actions;
  }

  return actions.map(
    bindDetailer({
      setCurrentDetailer,
      setDetailedTuple,
      detailers,
    }),
  );
}

export { getRemainingActions, incrementActions };
