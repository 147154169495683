import { Grid, withStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import orange from '@material-ui/core/colors/orange';
import Form from '../components/Form';
import { Submit } from '../components/form';
import SubmitButton from '../components/form/SubmitButton';
import qcomm from '../core/comm/queries';
import form from '../core/components/form/actions';

function styles(theme) {
  const lgUp = theme.breakpoints.up('lg');

  return {
    titleColor: { color: orange[600] },
    defaultSize: {
      [lgUp]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    smallSize: {
      [lgUp]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    paper: {
      padding: '24px',
    },
  };
}

function SimpleForm(props) {
  const {
    size,
    paper,
    submit,
    classes,
    children,
    doSubmit,
    formProps,
    isSubmiting,
    communicating,
    disableSubmit,
    customSubmitButton,
    customSubmitButtonProps,
    disableDefaultSubmitButton,
  } = props;
  const Wrapper = paper ? Paper : 'div';
  const CustomSubmitButton = customSubmitButton;
  const submitButtonProps = {
    disabled: communicating,
    loading: isSubmiting,
    onClick: doSubmit,
  };

  return (
    <Form {...formProps} className={classes[`${size}Size`]}>
      <Wrapper className={classNames(paper && classes.paper)}>
        <Grid container>
          {children}
          {!disableDefaultSubmitButton && (
            <Grid item xs={12}>
              <div style={{ witdth: '100%', marginTop: '16px' }}>
                <SubmitButton
                  {...submitButtonProps}
                  disableSubmit={disableSubmit}
                >
                  {submit.buttonText}
                </SubmitButton>
              </div>
            </Grid>
          )}
          {customSubmitButton && disableDefaultSubmitButton && (
            <Grid item xs={12}>
              <CustomSubmitButton
                {...submitButtonProps}
                {...customSubmitButtonProps}
              />
            </Grid>
          )}
        </Grid>
      </Wrapper>
    </Form>
  );
}

function mapStateToProps(state, ownProps) {
  const { formProps, submit } = ownProps;

  const resource = submit && submit.getIn(['request', 'url']);
  const { view } = formProps;

  return {
    isSubmiting: resource && qcomm.isSending(state, view, resource),
    communicating: qcomm.isCommunicating(state),
  };
}

function mapDispatchToProps(dispatch, { formProps, submit, invalidateCache }) {
  const { view, id = 'form' } = formProps;
  if (!submit) {
    return bindActionCreators(
      {
        clean: () => form.clean(view, id),
      },
      dispatch,
    );
  }

  const s = submit
    .setIn(['request', 'view'], view)
    .set('invalidateCache', invalidateCache);
  const doSubmit = Form.createSubmitFn(id, s);
  return bindActionCreators(
    {
      clean: () => form.clean(view, id),
      doSubmit,
    },
    dispatch,
  );
}

SimpleForm.defaultProps = {
  disableDefaultSubmitButton: false,
  customSubmitButtonProps: null,
  customSubmitButton: null,
  invalidateCache: null,
  size: 'default',
  doSubmit: null,
  submit: Submit(),
  paper: false,
};

SimpleForm.propTypes = {
  disableDefaultSubmitButton: PropTypes.bool,
  customSubmitButton: PropTypes.oneOfType([PropTypes.any]),
  customSubmitButtonProps: PropTypes.objectOf(PropTypes.any),
  size: PropTypes.oneOf(['small', 'default']),
  submit: PropTypes.instanceOf(Submit),
  invalidateCache: PropTypes.func,
  doSubmit: PropTypes.func,
  communicating: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  formProps: PropTypes.shape(Form.propTypes).isRequired,
  paper: PropTypes.bool,
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SimpleForm)),
);
