import React from 'react';
import PropTypes from 'prop-types';
import { List, Map, fromJS } from 'immutable';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';
import FilterRow from './FilterRow';
import getFilters from './filters-utils';
import getPredicates from './predicates-utils';
import { transformFieldName } from './common-utils';

let rowLastUid = 0;

function filterFn(f) {
  return f.get('field') && f.get('operator') && f.get('value');
}

function transformFilters(formState, filters) {
  const state = formState.filter(filterFn);
  const filtersList = getFilters(state, filters);
  const predicates = getPredicates(state, filters);
  return [predicates, filtersList];
}

function FilterForm(props) {
  const {
    id,
    view,
    open,
    save,
    rowsId,
    onClose,
    onFilter,
    setField,
    formState,
    deleteRow,
    setRowsId,
    setOnFilter,
    extraFilters,
    defaultState,
    setFormState,
    filters: originalFilters,
    ...others
  } = props;

  const [filters, fieldOptions] = React.useMemo(() => {
    const transformedFilters = fromJS(originalFilters).map((tuple) =>
      tuple.set('field', transformFieldName(tuple.get('field'))),
    );
    const list = List(
      transformedFilters.map((f) =>
        Map({
          key: f.get('field'),
          value: f.get('field'),
          label: f.get('label'),
        }),
      ),
    );
    return [transformedFilters, list];
  }, [originalFilters]);

  React.useEffect(() => {
    /**
     * ? Será que tem alguma forma de verificar se o
     * ? onFilter mudou sem dar loop infinito?
     */
    if (onFilter) {
      setOnFilter(onFilter);
    }
    if (defaultState) {
      const initalFilters = fromJS(defaultState);
      const initialState = Map({ filters: initalFilters });
      setFormState(initialState);
      let newIDs = List([]);
      for (let i = 0; i < initalFilters.size; ++i) {
        rowLastUid += 1;
        newIDs = newIDs.push(rowLastUid);
      }
      setRowsId(newIDs);
      if (onFilter) {
        const [predicates, filtersList] = transformFilters(
          initalFilters,
          filters,
        );
        save(Map({ '!@predicates': predicates, '!@filters': filtersList }));

        if (onFilter && filtersList.size > 0) {
          if (extraFilters.size > 0) {
            onFilter(filtersList.concat(extraFilters));
          } else {
            onFilter(filtersList);
          }
        }
      }
    }
  }, []);

  const rows = React.useMemo(
    () =>
      rowsId.map((uid, index) => {
        const getFilter = (id) => filters.find((f) => f.get('field') === id);
        const currentFilter = getFilter(formState.getIn([index, 'field']));
        return (
          <FilterRow
            index={index}
            rowsId={rowsId}
            key={String(uid)}
            setField={setField}
            setRowsId={setRowsId}
            getFilter={getFilter}
            fieldsList={fieldOptions}
            currentFilter={currentFilter || Map()}
            addRow={() => {
              rowLastUid += 1;
              setRowsId(rowsId.push(rowLastUid));
            }}
            removeRow={(i) => {
              setRowsId(rowsId.delete(i));
              deleteRow(i);
            }}
          />
        );
      }),
    [filters, rowsId, formState],
  );

  return (
    <Form id={id} view={view}>
      <Dialog
        open={open}
        title="Filtrar"
        onClose={onClose}
        id="filterFormDialog"
        {...others}
      >
        <DialogContent>{rows}</DialogContent>
        <DialogActions style={{ padding: '0px 24px 16px' }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ minWidth: '100px' }}
            onClick={(e) => {
              onClose(e);
              const [predicates, filtersList] = transformFilters(
                formState,
                filters,
              );
              save(
                Map({ '!@predicates': predicates, '!@filters': filtersList }),
              );
              if (onFilter && filtersList.size > 0) {
                if (extraFilters.size > 0) {
                  onFilter(filtersList.concat(extraFilters));
                } else {
                  onFilter(filtersList);
                }
              }
            }}
          >
            <b>Filtrar</b>
          </Button>
        </DialogActions>
      </Dialog>
    </Form>
  );
}

FilterForm.defaultProps = {
  id: 'filterForm',
  onFilter: () => null,
  defaultState: null,
};

FilterForm.propTypes = {
  /**
   * identificador do formulário.
   */
  id: PropTypes.string,

  /**
   * View do formulário.
   */
  view: PropTypes.string.isRequired,
  /**
   * Estado de visualização da `Dialog`.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Função a ser chamada para fechar a `Dialog`.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * Callback quando o usuário faz submit do formulário.
   */
  onFilter: PropTypes.func,

  /**
   * Filtros padrões do formulário.
   */
  defaultState: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      operator: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
    }),
  ),

  /**
   * Propriedades de cada filtro.
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        .isRequired, // campo em que se encontra o valor
      label: PropTypes.string.isRequired,
      condition: PropTypes.func, // condição de filtragem (apenas predicados)
      view: PropTypes.string,
      type: PropTypes.string,
      reload: PropTypes.bool, // Se o filtro deve ou não chamar o back
      options: PropTypes.instanceOf(List),
    }),
  ).isRequired,

  // * Props de estado
  rowsId: PropTypes.instanceOf(List).isRequired,
  setRowsId: PropTypes.func.isRequired,
  formState: PropTypes.instanceOf(List).isRequired,
  deleteRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
};

export default FilterForm;
