import React from 'react';
import { Link } from 'react-router-dom';
import { Fab, Zoom } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { colors } from '../../theme';

function styles(theme) {
  const u = theme.spacing(1);
  const down = theme.breakpoints.down('md');

  return {
    fab: {
      zIndex: 9999,
      right: u * 6,
      bottom: u * 3,
      color: 'white',
      position: 'fixed',
      [down]: {
        right: u,
        bottom: u,
      },
    },
    button: {
      backgroundColor: colors.warning,
      '&:hover': {
        backgroundColor: colors.warningDark,
      },
    },
  };
}

function MainActionButtonComponent(props) {
  const { link, classes, children, ...rest } = props;
  const component = link ? { to: link, component: Link } : null;

  return (
    <Zoom unmountOnExit in>
      <Fab
        className={classNames(classes.button, classes.fab)}
        {...rest}
        {...component}
      >
        {children}
      </Fab>
    </Zoom>
  );
}

const MainActionButton = withStyles(styles)(MainActionButtonComponent);

MainActionButton.renderDefault = (action) => {
  if (!action) {
    return null;
  }
  if (typeof action === 'string') {
    return (
      <MainActionButton link={action}>
        <AddIcon />
      </MainActionButton>
    );
  }
  if (typeof action === 'function') {
    return (
      <MainActionButton onClick={action}>
        <AddIcon />
      </MainActionButton>
    );
  }
  const { icon, ...rest } = action;
  return <MainActionButton {...rest}>{icon || <AddIcon />}</MainActionButton>;
};

export default MainActionButton;
