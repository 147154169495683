/**
 * Author: João Lucas, 04/2020
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

function styles(theme) {
  const u = theme.spacing(1);
  const lgUp = theme.breakpoints.up('lg');
  const mdDown = theme.breakpoints.down('md');

  return {
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      [mdDown]: {
        position: 'relative',
        top: -3 * u,
      },
    },
    img: {
      width: 260,
      height: 'auto',
      [lgUp]: {
        width: 416,
      },
    },
  };
}

function Header(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <img alt="Participe" src="/img/logotipo.svg" className={classes.img} />
    </div>
  );
}

export default withStyles(styles)(Header);
