import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import storage from "../../core/storage/actions";
import qstorage from "../../core/storage/queries";

const useStyles = makeStyles((theme) => {
  const { white } = theme.palette.common;
  const u = theme.spacing(1);
  const w = u * 30;
  const createT = theme.transitions.create;
  const sharpT = theme.transitions.easing.sharp;
  const enterT = theme.transitions.duration.enteringScreen;
  const leaveT = theme.transitions.duration.leavingScreen;

  return {
    input: {
      color: white,
      overflow: "hidden",
    },
    underline: {
      borderBottom: `1px solid ${white}`,
      "&:after": {
        borderBottom: "1px solid #bbb",
      },
    },
    show: {
      width: w,
      whiteSpace: "nowrap",
      transition: createT("width", {
        duration: enterT,
        easing: sharpT,
      }),
    },
    hide: {
      width: 0,
      overflowX: "hidden",
      transition: createT("width", {
        duration: leaveT,
        easing: sharpT,
      }),
    },
  };
});

function DynamicSearchField(props) {
  const { searchTerm, setSearchTerm, searchFieldOpen, setSearchFieldOpen, dynamicSearchField } =
    props;
  const inputRef = React.useRef(null);
  const classes = useStyles();

  const performAction = () => {
    setSearchFieldOpen(true);
    inputRef.current.focus();
    dynamicSearchField(searchTerm);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      performAction();
    }
  };

  return (
    <>
      {!searchFieldOpen && (
        <IconButton
          color="inherit"
          onClick={() => {
            setSearchFieldOpen(true);
            inputRef.current.focus();
          }}
        >
          <SearchIcon />
        </IconButton>
      )}
      <Input
        autoFocus
        value={searchTerm}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        classes={{ underline: classes.underline }}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={classNames(classes.input, classes.show, !searchFieldOpen && classes.hide)}
        onBlur={() => {
          if (!searchTerm) {
            setSearchFieldOpen(false);
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color="inherit"
              aria-label="Fechar busca rápida"
              onClick={() => {
                setSearchFieldOpen(false);
                setSearchTerm("");
                dynamicSearchField(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const { view, table = "table" } = ownProps;
  return {
    searchTerm: qstorage.get(state, view, [table, "search"], ""),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, table = "table" } = ownProps;
  const actions = {
    setSearchTerm: (term) => storage.set(view, [table, "search"], term),
  };

  return bindActionCreators(actions, dispatch);
}

DynamicSearchField.defaultProps = {
  table: "table",
};

DynamicSearchField.propTypes = {
  /**
   * view em que os dados serão salvos.
   * Deve ser a mesma view passada para o `FilterForm`.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  view: PropTypes.string.isRequired,
  /**
   * Id da `Table` em que os filtros serão usados.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  table: PropTypes.string,
  /**
   * Indica se o campo de busta está em modo de digitação.
   */
  searchFieldOpen: PropTypes.bool.isRequired,
  /**
   * Função de alteração da prop `searchFieldOpen`.
   */
  setSearchFieldOpen: PropTypes.func.isRequired,

  // * Props de estado
  // eslint-disable-next-line react/forbid-prop-types
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicSearchField);
