import React from 'react';
import { Typography, DialogContent, Hidden } from '@material-ui/core';
import { Dialog } from '../../../../components';
import SpeedDialMenu from '../../SpeedDialMenu';
import DrawerAction from './DrawerAction';

function DialogDetail(props) {
  const { id, open, onClose, title, actions, tuple, children } = props;
  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      title={
        <Typography variant="h6" color="inherit">
          {title} <DrawerAction actions={actions} tuple={tuple} />
        </Typography>
      }
    >
      <DialogContent>{children}</DialogContent>
      <Hidden lgUp>
        <SpeedDialMenu
          actions={actions.map((a) => {
            if (typeof a.link !== 'function') {
              return a;
            }
            if (!tuple) {
              return { ...a, link: '' };
            }

            const { link } = a;
            return { ...a, link: link(tuple) };
          })}
        />
      </Hidden>
    </Dialog>
  );
}

export default DialogDetail;
