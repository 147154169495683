import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { DateFilter } from '../../../components';
import Detail from './Detail';
import MainActionButton from '../MainActionButton';

function TableWrapper(props) {
  const {
    hide,
    preamble,
    epilogue,
    children,
    onFilter,
    mainAction,
    detailProps,
    // props de componentes -------------------------------
    dateFilterProps,
  } = props;

  return (
    <>
      <div
        style={{
          padding: 16,
          width: '100%',
          paddingBottom: 32,
          justifyContent: 'center',
          display: !hide && dateFilterProps ? 'flex' : 'none',
        }}
      >
        {dateFilterProps && (
          <DateFilter {...dateFilterProps} onFilter={onFilter} />
        )}
      </div>
      {!hide && preamble && preamble()}
      {!hide && children}
      {detailProps && <Detail {...detailProps} />}
      {MainActionButton.renderDefault(mainAction)}
      {epilogue && epilogue()}
    </>
  );
}

TableWrapper.defaultProps = {
  hide: false,
  preamble: null,
  epilogue: null,
  mainAction: null,
  detailProps: null,
  dateFilterProps: null,
};

TableWrapper.propTypes = {
  /**
   * Omite a tabela, datefilter e preamble
   * Usada quando a tabela está sendo carregada.
   */
  hide: PropTypes.bool,
  /**
   * Função que renderiza um componente antes da tabela.
   */
  preamble: PropTypes.func,
  /**
   * Função que renderiza um componente depois da tabela.
   */
  epilogue: PropTypes.func,
  /**
   * Propriedade para o `FloatButton` da tela.
   * Geralmente usado para redirecionar o usuário para a tela de cadastro.
   */
  mainAction: PropTypes.oneOfType([
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func,
      link: PropTypes.string,
    }),
    PropTypes.string,
    PropTypes.func,
  ]),
  /**
   * Propriedades do componente de detalhes que pode ser um
   * `Drawer` ou um `Dialog`.
   */
  detailProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    currentAction: PropTypes.object.isRequired,
    tuple: PropTypes.instanceOf(Map).isRequired,
    actions: PropTypes.arrayOf(PropTypes.object).isRequired,
    detailTarget: PropTypes.oneOf(['drawer', 'dialog']).isRequired,
  }),
  /**
   * Propriedades a serem repassadas para o componente `DateFilter`.
   */
  dateFilterProps: PropTypes.shape(DateFilter.propTypes),
};

export default TableWrapper;
