import { OrderedSet } from "immutable";

import { Filter } from "../../../core/comm/index";
import { processAutocomplete } from "../../../core/util";
import { Operators } from "./common-utils";

const OperatorToReduce = {
  [Operators.GREATER_OR_EQUAL]: true,
  [Operators.LESS_OR_EQUAL]: true,
  [Operators.EQUALS]: true,
};

function groupValue(filters, op) {
  if (op === Operators.GREATER_OR_EQUAL) {
    return filters.reduce((acc, v) => Math.max(acc, v.get("value")), 0);
  }
  if (op === Operators.LESS_OR_EQUAL) {
    return filters.reduce((acc, v) => Math.min(acc, v.get("value")), filters.getIn([0, "value"]));
  }
  return filters.map((item) => item.get("value"));
}

/**
 * Para casos onde exista mais de um filtro com o mesmo campo e operador
 * essa função agrupa esse casos transformando-os em um objeto `Filter`
 * do formato `{field: <fieldName>, op: IN, value: []}`
 *
 * @param {List} formState
 * @return {List}
 */
function groupFilters(formState) {
  const groups = formState.groupBy((tuple) => {
    if (OperatorToReduce[tuple.get("op")]) {
      return tuple.get("field") + tuple.get("op");
    }
    return tuple.get("field") + tuple.get("op") + tuple.get("value");
  });
  const groupedState = groups.map((value) => {
    const needMerge = value.size > 1;

    if (!needMerge) {
      return value.get(0);
    }

    const op = value.getIn([0, "op"]);
    return Filter({
      field: value.getIn([0, "field"]),
      op: op === Operators.EQUALS ? "IN" : op,
      value: groupValue(value, op),
    });
  });

  return groupedState.toList();
}

function transformValue(value) {
  if (value === "@null") {
    return null;
  }
  if (OrderedSet.isOrderedSet(value)) {
    return value.first();
  }
  if (Array.isArray(value)) {
    return processAutocomplete(value);
  }
  return value;
}

/**
 * Transforma os itens no record `Filter`.
 *
 * @param {List} formState
 * @return {List}
 */
function transformFilters(formState) {
  return formState.map((tuple) => {
    const value = tuple.get("value");
    const realValue = transformValue(value);

    return Filter({
      field: tuple.get("field"),
      op: tuple.get("operator"),
      value: realValue,
    });
  });
}

/**
 * Separa e transforma os filtros que serão enviados para o backend.
 *
 * @param {List} formState
 * @param {List} filters
 * @return {List}
 */
function getFilters(formState, filters) {
  // Remove os filtros que serão predicados.
  const filtersOnly = filters.filter((el) => Boolean(el && el.get("reload")));

  // Seleciona os filtros do formulário para transformação.
  const cleanFormState = formState.filter((tuple) =>
    Boolean(filtersOnly.find((f) => f.get("field") === tuple.get("field"))),
  );

  // Transforma os filtros
  const tranformedFilters = transformFilters(cleanFormState);

  // Agrupa os filtros com campos e operadores iguais.
  const groupedFilters = groupFilters(tranformedFilters);

  return groupedFilters;
}

export default getFilters;
