import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RestorePage } from '@material-ui/icons';
import orange from '@material-ui/core/colors/orange';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SubmitButton from '../components/form/SubmitButton';
import SubmitButton2 from '../components/form/SubmitButton2';
import form from '../core/components/form/actions';
import { Submit } from '../components/form';
import qcomm from '../core/comm/queries';
import Section from '../components/Section';
import Form from '../components/Form';
import FormControlPanel from './_internal/FormControlPanel';
import Base from './Base';

function styles(theme) {
  const u = theme.spacing(1);

  return {
    titleColor: { color: orange[600] },
    margin: {
      marginTop: 2.5 * u,
    },
  };
}

function ControlPanel(props) {
  const { hideCleanButton, actions, onClean, clean } = props;
  const onClick = () => {
    if (onClean) {
      onClean();
    }
    clean();
  };

  return (
    <FormControlPanel
      actions={
        hideCleanButton
          ? actions
          : [
              ...actions,
              {
                tooltip: 'Limpar o formulário',
                icon: <RestorePage />,
                onClick,
              },
            ]
      }
    />
  );
}

function CustomSection(props) {
  const { children, submitButtonProps, hideSubmitButton, sectionProps } = props;

  return (
    <Section {...sectionProps}>
      <Grid container>
        {children}
        {!hideSubmitButton && (
          <Grid item xs={12}>
            <SubmitButton2 {...submitButtonProps} />
          </Grid>
        )}
      </Grid>
    </Section>
  );
}

/**
 * Template que exibe acima do componente `Form` um
 * cabeçalho com (da esquerda para a direita) botão
 * de retornar à página anterior, título, subtítulo
 * -- abaixo do anterior --, e botões de ação.
 * Por padrão, o único botão de ação é o de limpar o
 * formulário, podendo ser definidos outros (TODO).
 * As ações em telas para desktop são renderizados
 * do lado direto do cabeçalho. Já em telas de mobile,
 * são dispostos num `SpeedDialMenu`.
 */
function FormView(props) {
  const {
    title,
    clean,
    submit,
    onClean,
    actions,
    classes,
    children,
    doSubmit,
    subtitle,
    noPadding,
    formProps,
    denyAccess,
    closeButton,
    actionPanel,
    withSection,
    isSubmiting,
    sectionProps,
    communicating,
    disableSubmit,
    hideCleanButton,
    actionPanelProps,
    hideReturnButton,
    hideSubmitButton,
    hideToggleMenuButton,
  } = props;
  const CustomSubmitButton = actionPanel;
  const submitButtonProps = {
    disabled: communicating || disableSubmit,
    onClick: doSubmit,
  };

  const Wrapper = withSection ? CustomSection : React.Fragment;
  const wrapperProps = withSection
    ? { submitButtonProps, sectionProps, hideSubmitButton }
    : {};

  return (
    <Base
      title={title}
      subtitle={subtitle}
      noPadding={noPadding}
      denyAccess={denyAccess}
      closeButton={closeButton}
      hideReturnButton={hideReturnButton}
      hideToggleMenuButton={hideToggleMenuButton}
      controlPanel={
        <ControlPanel
          hideCleanButton={hideCleanButton}
          actions={actions}
          onClean={onClean}
          clean={clean}
        />
      }
    >
      <Form {...formProps}>
        <div className={classes.margin}>
          <Wrapper {...wrapperProps}>{children}</Wrapper>
          {!actionPanel && !hideSubmitButton && !withSection && (
            <div style={{ witdth: '100%' }}>
              <SubmitButton loading={isSubmiting} {...submitButtonProps}>
                {submit.buttonText}
              </SubmitButton>
            </div>
          )}
          {actionPanel && !hideSubmitButton && (
            <CustomSubmitButton
              submitButtonProps={submitButtonProps}
              {...actionPanelProps}
            />
          )}
        </div>
      </Form>
    </Base>
  );
}

function mapDispatchToProps(dispatch, { formProps, submit, invalidateCache }) {
  if (!submit) {
    return {};
  }

  const { view, id = 'form' } = formProps;
  const s = submit
    .setIn(['request', 'view'], view)
    .set('invalidateCache', invalidateCache);
  const doSubmit = Form.createSubmitFn(id, s);
  return bindActionCreators(
    {
      clean: () => form.clean(view, id),
      doSubmit,
    },
    dispatch,
  );
}

function mapStateToProps(state, ownProps) {
  const { formProps, submit } = ownProps;
  const resource = submit ? submit.getIn(['request', 'url']) : null;
  const { view } = formProps;

  return {
    isSubmiting: !submit ? false : qcomm.isSending(state, view, resource),
    communicating: qcomm.isCommunicating(state),
  };
}

FormView.defaultProps = {
  clean: null,
  actions: [],
  sectionProps: null,
  onClean: null,
  subtitle: null,
  doSubmit: null,
  submit: Submit(),
  noPadding: false,
  denyAccess: false,
  actionPanel: null,
  closeButton: false,
  withSection: false,
  invalidateCache: null,
  disableSubmit: false,
  actionPanelProps: null,
  hideCleanButton: false,
  hideReturnButton: false,
  hideSubmitButton: false,
  hideToggleMenuButton: false,
};

FormView.propTypes = {
  withSection: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sectionProps: PropTypes.object,
  /**
   * Função para limpar os campos do formulário.
   * Gerada automaticamente pelo mapDispatchToProps.
   */
  clean: PropTypes.func,
  /**
   * Função chamada quando o formulário é limpo.
   */
  onClean: PropTypes.func,
  /**
   * Se `true`, o botão de retornar, que ficar à
   * esquerda do título da página, não será exibido.
   */
  hideReturnButton: PropTypes.bool,
  /**
   * Se `true`, o botão de limpar o formulário, que
   * fica à direita do título da página, não será
   * exibido.
   */
  hideCleanButton: PropTypes.bool,
  /**
   * Omite o botão de menu que fica a esquerda do título.
   */
  hideToggleMenuButton: PropTypes.bool,
  /**
   * Omite o botão de submit.
   */
  hideSubmitButton: PropTypes.bool,
  /**
   * Componente a ser rederizado no lugar do botão
   * de submeter os dados do formulário. Além de
   * receber `actionPanelProps` na hora da criação
   * do elemento, também lhe será passada a prop
   * `submitButtonProps`, caso se deseje criar o
   * mesmo comportamento do componente natural.
   */
  actionPanel: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Objeto passado como props ao `actionPanel`.
   */
  actionPanelProps: PropTypes.objectOf(PropTypes.any),
  /**
   * Título da página. Em geral o título da página
   * deve ser um nome que identifique o objeto sendo
   * editado, caso haja algum.
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtítulo da página. Em geral ele deve descrever
   * o "tipo" de dado sendo editado ou criado no
   * formulário, como "Cadastro de conta bancária"
   * ou "Cadastro de formando".
   */
  subtitle: PropTypes.string,
  /**
   * Um objeto do tipo `Submit` que descreve o
   * processo de submissão dos dados do formulário.
   * A 'view' da 'request' e função de invalidação
   * de cache são configuradas automaticamente pela
   * FormView. A 'view' será a mesma passada nas
   * `formProps`.
   */
  submit: PropTypes.instanceOf(Submit),
  /**
   * Função chamada quando a submissão do formulário
   * retorna um status de sucesso (200 OK). Usada
   * para invalidar dados armazenados no frontend
   * que possam ter sido afetados no backend pela
   * submissão do formulário. Em geral os dados que
   * alimentam a tela de listagem que antecede o
   * formulário sempre devem ser invalidados.
   */
  invalidateCache: PropTypes.func,
  /**
   * Função gerada automaticamente no mapDispatchToProps
   * executa a descrição passada na prop `submit`.
   */
  doSubmit: PropTypes.func,
  /**
   * Flag injetada pelo mapStateToProps ao FormView
   * para informar se a aplicação está enviando ou
   * esperando dados da API.
   */
  communicating: PropTypes.bool.isRequired,
  /**
   * Objeto injetado automaticamente pelo withStyles.
   */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /**
   * Flag para desabilitar o botão submit do formulário.
   */
  disableSubmit: PropTypes.bool,
  /**
   * Props passadas sem modificação ao componente
   * Form.
   */
  formProps: PropTypes.shape(Form.propTypes).isRequired,
  /**
   * Ações que ficam disponíveis em um menu na altura do título a direta.
   * Funcionam igual a propriedade `actions` da `Table`.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      link: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  /**
   * Controle de permissão para acesso ao template.
   */
  denyAccess: PropTypes.bool,
  /**
   * Se `true`, o botão de fechar é exibido a
   * direita do título da página.
   */
  closeButton: PropTypes.bool,
  /**
   * Se `true` template não possui padding
   */
  noPadding: PropTypes.bool,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FormView),
);
