import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { withRouter } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../components/IconButton';

const mobileSizes = ['xs', 'sm', 'md'];

function ContentHeader(props) {
  const {
    title,
    className,
    history,
    controlPanel,
    hideReturnButton,
    returnButton,
    width,
    onReturn,
  } = props;

  const isMobile = mobileSizes.includes(width);

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        paddingLeft: isMobile ? '0px' : '16px',
        paddingRight: isMobile ? '0px' : '16px',
        paddingTop: '16px',
      }}
    >
      <span>
        {!hideReturnButton && (
          <IconButton
            style={{ display: 'inline', padding: '8px' }}
            tooltip="Página anterior"
            onClick={(e) => {
              if (returnButton) {
                history.push(returnButton);
              }
              if (!returnButton) {
                history.goBack();
              }
              if (onReturn) {
                onReturn(e);
              }
            }}
          >
            <ArrowBack style={{ fontSize: 24 }} color="primary" />
          </IconButton>
        )}
      </span>
      <span style={{ paddingTop: 4, flexGrow: 1 }}>
        <Typography
          gutterBottom
          variant="h6"
          color="primary"
          style={{ fontWeight: 600, textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
      </span>
      <span>{controlPanel}</span>
    </div>
  );
}

export default withRouter(withWidth()(ContentHeader));
