import 'moment/locale/pt-br';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { BrowserRouter } from 'react-router-dom';
import {
  CssBaseline,
  MuiThemeProvider,
  CircularProgress,
} from '@material-ui/core';
import ErrorBoundary from '../../applets/error/ErrorBoundary';
import Notifier from './Notifier';
import RootComponent from './RootComponent';
import store from '../../core/store';
import { theme } from '../../theme';

const appDB = store.get();

function Loading() {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '100 0' }}
    >
      <CircularProgress />
    </div>
  );
}

/**
 * Componente, que deve ser posto na raiz de aplicação
 * (pública e não pública), responsável por, principalmente,
 * configura e prover o storage do redux, configurar o tema
 * do Material-UI, exibir o `SnackBar` que informa se a
 * aplicação está se comunicando com o backend e requisitar
 * as configurações do usuário à api.
 */
function AppRoot(props) {
  const { children } = props;

  return (
    <Provider store={appDB}>
      <CssBaseline>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            <BrowserRouter>
              <RootComponent {...props}>
                <Suspense fallback={<Loading />}>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </Suspense>
              </RootComponent>
            </BrowserRouter>
            <Notifier />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </CssBaseline>
    </Provider>
  );
}

export default AppRoot;
