import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import { List } from "immutable";
import PropTypes from "prop-types";
import React from "react";

import Browser from "../../hooks/browser";
import ContentHeader from "./ContentHeader";
import DynamicSearchField from "./DynamicSearchField";
import FitlerActions from "./FilterActions";
import FilterForm from "./FilterForm";
import SearchField from "./SearchField";
import ViewAppBar from "./ViewAppBar";

function styles(theme) {
  const u = theme.spacing(1);
  const pt = u * 2.5;
  const pb = u * 8;
  const ph = u * 3;
  const down = theme.breakpoints.down("md");
  const lgDown = theme.breakpoints.down("lg");
  const xlUp = theme.breakpoints.up("xl");

  return {
    padding: {
      [xlUp]: {
        padding: u / 2,
        paddingLeft: "12%",
        paddingRight: "12%",
      },
      [lgDown]: {
        padding: u / 2,
      },
    },
    content: {
      paddingTop: pt,
      paddingBottom: pb,
      paddingLeft: ph,
      paddingRight: ph,
      [down]: {
        paddingTop: u * 4,
        paddingBottom: u * 6,
        paddingLeft: u / 4,
        paddingRight: u / 4,
      },
    },
    noPadding: {
      padding: 0,
    },
  };
}

function renderActionPanel(params) {
  const {
    filterProps,
    actionPanel,
    setOpenFilter,
    searchField,
    searchFieldOpen,
    setSearchFieldOpen,
    dynamicSearchField,
  } = params;
  if (!filterProps && !searchField && !dynamicSearchField) {
    return actionPanel;
  }
  const { classes, ...others } = filterProps || {};

  return (
    <>
      {filterProps && (
        <FitlerActions {...others} formId={filterProps.id} onOpen={() => setOpenFilter(true)} />
      )}
      {actionPanel}
      {searchField && !dynamicSearchField && (
        <SearchField
          {...searchField}
          searchFieldOpen={searchFieldOpen}
          setSearchFieldOpen={setSearchFieldOpen}
        />
      )}
      {dynamicSearchField && !searchField && (
        <DynamicSearchField
          {...searchField}
          dynamicSearchField={dynamicSearchField}
          searchFieldOpen={searchFieldOpen}
          setSearchFieldOpen={setSearchFieldOpen}
        />
      )}
    </>
  );
}

/**
 * Template que exibe acima do componente `Form` um
 * cabeçalho com (da esquerda para a direita) botão
 * de retornar à página anterior, título, subtítulo
 * -- abaixo do anterior --, e botões de ação.
 * Por padrão, o único botão de ação é o de limpar o
 * formulário, podendo ser definidos outros (TODO).
 * As ações em telas para desktop são renderizados
 * do lado direto do cabeçalho. Já em telas de mobile,
 * são dispostos num `SpeedDialMenu`.
 */
function Base(props) {
  const {
    width,
    title,
    classes,
    subtitle,
    children,
    onReturn,
    hideTitle,
    noPadding,
    denyAccess,
    filterProps,
    actionPanel,
    controlPanel,
    returnButton,
    searchField,
    dynamicSearchField,
    closeButton,
    hideReturnButton,
    hideToggleMenuButton,
  } = props;
  const [searchFieldOpen, setSearchFieldOpen] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const isMobile = ["xs", "sm", "md"].includes(width);

  const redirect = Browser.useGo("/");
  React.useEffect(() => {
    if (denyAccess) {
      redirect();
    }
  }, [denyAccess]);

  return (
    <>
      <ViewAppBar
        title={title}
        closeButton={closeButton}
        className={classes.padding}
        hideToggleMenuButton={hideToggleMenuButton}
        hideTitle={hideTitle || (isMobile && searchFieldOpen)}
        actionPanel={renderActionPanel({
          setSearchFieldOpen,
          searchFieldOpen,
          setOpenFilter,
          actionPanel,
          filterProps,
          searchField,
          dynamicSearchField,
        })}
      />
      <div className={noPadding ? classes.noPadding : classes.padding}>
        {subtitle && (
          <ContentHeader
            title={subtitle}
            classes={classes}
            subtitle={subtitle}
            onReturn={onReturn}
            returnButton={returnButton}
            controlPanel={controlPanel}
            hideReturnButton={hideReturnButton}
          />
        )}
        <div className={noPadding ? classes.noPadding : classes.content}>{children}</div>
      </div>
      {filterProps && (
        <FilterForm open={openFilter} onClose={() => setOpenFilter(false)} {...filterProps} />
      )}
    </>
  );
}

Base.defaultProps = {
  subtitle: null,
  hideTitle: false,
  actionPanel: null,
  controlPanel: null,
  returnButton: null,
  hideReturnButton: false,
  hideToggleMenuButton: false,
  onReturn: null,
  filterProps: null,
  searchField: null,
  dynamicSearchField: null,
  denyAccess: false,
  closeButton: false,
  noPadding: false,
};

Base.propTypes = {
  hideTitle: PropTypes.bool,
  /**
   * Se `true`, o botão de retornar, que ficar à
   * esquerda do título da página, não será exibido.
   */
  hideReturnButton: PropTypes.bool,
  /**
   * É passado como parâmetro para history.push
   */
  returnButton: PropTypes.string,
  /**
   * Função chamada quando o botão de voltar é pressioando.
   */
  onReturn: PropTypes.func,
  /**
   * TODO
   */
  hideToggleMenuButton: PropTypes.bool,
  /**
   * Título da página. Em geral o título da página
   * deve ser um nome que identifique o objeto sendo
   * editado, caso haja algum.
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtítulo da página. Em geral ele deve descrever
   * o "tipo" de dado sendo editado ou criado no
   * formulário, como "Cadastro de conta bancária"
   * ou "Cadastro de formando".
   */
  subtitle: PropTypes.string,
  /**
   * TODO comment
   */
  controlPanel: PropTypes.element,
  /**
   * Props de filtro.
   */
  filterProps: PropTypes.shape({
    id: PropTypes.string,
    validator: PropTypes.func,
    table: PropTypes.string,
    view: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired, // campo em que se encontra o valor
        label: PropTypes.string.isRequired, // label do input
        condition: PropTypes.func, // condição de filtragem
        view: PropTypes.string, // view
        type: PropTypes.string, // formatação do campo
        reload: PropTypes.bool, // Se o filtro deve chamar o back
        options: PropTypes.instanceOf(List),
      }),
    ),
  }),

  /**
   * Função que transforma um item da tabela em string
   *
   * Essa propriedade apenas importa
   * para sabe se vai usar o campo de busca e
   * pegar o id da tabela, caso necessário
   */
  searchField: PropTypes.shape({
    view: PropTypes.string.isRequired,
    table: PropTypes.string,
    stringfyTuple: PropTypes.func.isRequired,
  }),

  dynamicSearchField: PropTypes.func,

  /**
   * TODO comment
   */
  actionPanel: PropTypes.element,
  /**
   * Objeto injetado automaticamente pelo withStyles.
   */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /**
   * Controle permissão para acesso ao template.
   */
  denyAccess: PropTypes.bool,
  /**
   * Se `true`, o botão de fechar é exibido a
   * direita do título da página.
   */
  closeButton: PropTypes.bool,
  /**
   * Se `true` template não possui padding
   */
  noPadding: PropTypes.bool,
};

export default withStyles(styles)(withWidth()(Base));
