import React from 'react';
import { Alarm } from '@material-ui/icons';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';

function LogoutDialog() {
  return (
    <Dialog open onClose={() => null}>
      <div
        style={{
          color: 'red',
          paddingTop: 32,
          paddingBottom: 16,
          textAlign: 'center',
        }}
      >
        <Alarm style={{ fontSize: '3rem' }} />
      </div>
      <DialogContent>
        <DialogContentText>
          Sessão expirada. Você será redirecionado para a tela de login.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default LogoutDialog;
