// import { List } from 'immutable';
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { SimpleFilterForm, Table } from "../components";
import Base from "./Base";
// import { createSetObject } from '../core/util';
// import ActionPanel from './_internal/ActionPanel';
import TableWrapper from "./_internal/TableWrapper";
import { getRemainingActions, incrementActions } from "./_internal/table-util/actions";

// import {
//   useFilterOnMount,
//   createDateFilterHandler,
// } from './_internal/table-util/filtering';

function TableView(props) {
  const {
    id,
    view,
    title,
    subtitle,
    preamble,
    epilogue,
    onFilter,
    detailers,
    denyAccess,
    mainAction,
    tableProps,
    filterProps,
    searchField,
    detailTarget,
    dateFilterProps,
    showReturnButton,
    simpleFilterFormProps,
  } = props;

  //
  // Estado interno -------------------------------------------------
  //
  const [detailedTuple, setDetailedTuple] = React.useState(null);
  const [currentDetailer, setCurrentDetailer] = React.useState(null);

  //
  // Recálculo de props ---------------------------------------------
  //
  const tableID = tableProps.id;
  const actions = incrementActions({
    detailers,
    tableProps,
    setDetailedTuple,
    setCurrentDetailer,
  });

  return (
    <Base
      title={title}
      subtitle={subtitle}
      denyAccess={denyAccess}
      searchField={searchField}
      hideReturnButton={!showReturnButton}
      filterProps={filterProps && { ...filterProps, onFilter, view, table: tableID }}
    >
      <TableWrapper
        preamble={preamble}
        epilogue={epilogue}
        onFilter={onFilter}
        mainAction={mainAction}
        hide={tableProps.loading}
        dateFilterProps={dateFilterProps && { ...dateFilterProps, view, table: tableID }}
        detailProps={
          !currentDetailer || !detailedTuple
            ? null
            : {
                detailTarget,
                id: `${id}/detail`,
                tuple: detailedTuple,
                open: Boolean(detailedTuple),
                currentAction: currentDetailer,
                onClose: () => setDetailedTuple(null),
                actions: getRemainingActions(currentDetailer, actions),
              }
        }
      >
        <Paper>
          {simpleFilterFormProps && (
            <SimpleFilterForm
              view={view}
              id={`${id}/SimpleFilterForm`}
              {...simpleFilterFormProps}
            />
          )}
          <Table
            view={view}
            {...tableProps}
            actions={actions}
            stringfyTuple={searchField && searchField.stringfyTuple}
          />
        </Paper>
      </TableWrapper>
    </Base>
  );
}

TableView.defaultProps = {
  detailers: [],
  subtitle: null,
  preamble: null,
  epilogue: null,
  onFilter: null,
  mainAction: null,
  denyAccess: false,
  searchField: null,
  filterProps: null,
  id: "@!TableView",
  noFiltering: false,
  dateFilterProps: null,
  filterFormProps: null,
  detailTarget: "drawer",
  showReturnButton: false,
  simpleFilterFormProps: null,
};

TableView.propTypes = {
  id: PropTypes.string,
  view: PropTypes.string.isRequired,
  /**
   * Título da página.
   */
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  epilogue: PropTypes.func,
  preamble: PropTypes.func,
  /**
   * Link relativo para a tela seguinte; preferivelmente
   * uma tela de formulário.
   */
  mainAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func,
      link: PropTypes.string,
    }),
  ]),
  /**
   * Objeto passado como `props` ao componente `DataTable`.
   * Essa `prop` é instrumentada pela `DataTableView` para
   * filtrar os dados passados para injetar a função de
   * onClick na ação de detalhar.
   */
  tableProps: PropTypes.shape({
    ...Table.propTypes,
    view: PropTypes.string,
  }).isRequired,
  /**
   * Mostrar o componente de voltar para a tela anterior do
   * componente `Base`.
   */
  showReturnButton: PropTypes.bool,
  /**
   * Se `true`, a DataTable exibirá componentes de filtro
   * (FilterForm e DateFilter) e as tags de filtro, mas
   * não filtrará os dados passados na prop `tableProps`
   * no campo `data`.
   */
  noFiltering: PropTypes.bool,
  /**
   * Função do tipo `filter => void` que é chamada sempre
   * que o usuário clicar em [Filtrar] no `FilterForm` ou
   * um dos parâmetros do `DateFilter` for mudado.
   */
  onFilter: PropTypes.func,
  /**
   * Configura o componente `DateFilter`. Por padrão a view
   * utilizada será a mesma passada no `filterFormProps`.
   * - `field`: nome do campo no qual se aplicará o filtro.
   */
  dateFilterProps: PropTypes.shape({
    view: PropTypes.string,
    onFilter: PropTypes.func,
    dateField: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        startField: PropTypes.string.isRequired,
        endField: PropTypes.string.isRequired,
      }),
    ]),
    defaultRange: PropTypes.oneOf(["7days", "5days", "30days"]),
    defaultTab: PropTypes.oneOf(["daily", "monthly", "periodically", "last"]),
  }),
  /**
   * Configura o `FilterForm`.
   */
  filterFormProps: PropTypes.shape({
    view: PropTypes.string,
    filters: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  filterProps: PropTypes.shape({
    id: PropTypes.string,
    validator: PropTypes.func,
    table: PropTypes.string,
    view: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        condition: PropTypes.func,
        reload: PropTypes.bool,
      }),
    ).isRequired,
  }),
  /**
   * Configura o `SimpleFilterForm`.
   */
  simpleFilterFormProps: PropTypes.shape({
    view: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  /**
   * Lista de actions que invocarão o Drawer.
   * Para identificar quais as action devem receber
   * a função de abrir o drawer, é usado a propriedade
   * `reference` como id.
   * Quaisquer propriedade a mais serão repassadas para
   * o componente na hora de renderizar o drawer.
   */
  detailers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      reference: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    }),
  ),
  /**
   * Objeto injetado por `withStyles`.
   */
  detailTarget: PropTypes.oneOf(["drawer", "dialog"]),
  /**
   * Props do campo de busca para filtrar itens da tabela.
   */
  searchField: PropTypes.shape({
    view: PropTypes.string.isRequired,
    stringfyTuple: PropTypes.func.isRequired,
    table: PropTypes.string,
  }),
  /**
   * Controle de permissão para acesso ao template.
   */
  denyAccess: PropTypes.bool,
};

export default TableView;
