import React from 'react';
import PropTypes from 'prop-types';
import { NoticePanel } from '../components';
import Base from './Base';

function NoticeView(props) {
  const {
    hideActionButton,
    actionButton,
    subtitle,
    message,
    image,
    title,
  } = props;

  return (
    <Base title={title}>
      <NoticePanel
        hideActionButton={hideActionButton}
        actionButton={actionButton}
        message={message}
        title={subtitle}
        image={image}
      />
    </Base>
  );
}

NoticeView.defaultProps = {
  hideActionButton: false,
  actionButton: null,
  image: null,
  title: null,
};

NoticeView.prototypes = {
  subtitle: PropTypes.string,
  hideActionButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  actionButton: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({
      color: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ]),
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default NoticeView;
