import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

function from(topOp, top, leftOp, left) {
  return {
    display: 'block',
    top: `calc(45% ${topOp} ${top}px)`,
    left: `calc(50% ${leftOp} ${left}px)`,
  };
}

function styles(theme) {
  const u = theme.spacing(1);
  const animationTimingFunction = 'ease';
  const animationFillMode = 'forwards';
  const animationDuration = '0.9s';
  // const animationDuration = '3s';

  const logoIniSize = 10 * u;
  const logoEndSize = 24 * u;
  const logoIniTopPos = `calc(45% - ${logoIniSize / 2}px)`;
  const logoEndTopPos = `calc(45% - ${logoEndSize / 2}px)`;
  const logoIniLeftPos = `calc(50% - ${logoIniSize / 2}px)`;
  const logoEndLeftPos = `calc(50% - ${logoEndSize / 2}px)`;

  const satelliteSize = 5 * u;
  const distance = 20 * u;

  const logoFrom = {
    width: logoIniSize,
    height: logoIniSize,
    left: logoIniLeftPos,
    top: logoIniTopPos,
  };
  const logoTo = {
    width: logoEndSize,
    height: logoEndSize,
    left: logoEndLeftPos,
    top: logoEndTopPos,
  };

  const to = {
    display: 'none',
    top: `calc(45% - ${satelliteSize / 2}px)`,
    left: `calc(50% - ${satelliteSize / 2}px)`,
  };

  return {
    img: {
      width: '100%',
      height: 'auto',
      borderRadius: '50%',
      backgroundColor: 'rgb(227, 237, 240)',
    },
    stage: {
      width: '100%',
      display: 'flex',
      overflow: 'hide',
      height: 'calc(100vh - 4px)',
      backgroundColor: 'rgba(25, 121, 151, 0.1)',
    },
    /**
     * ----------------------------------------------------
     * Animation
     * ----------------------------------------------------
     */
    animation: {
      userSelect: 'none',
      position: 'absolute',
      animationFillMode,
      animationDuration,
      animationTimingFunction,
      // animationIterationCount: 'infinite',
    },
    reverse: {
      animationDuration: '0.6s',
    },
    satellite: {
      height: satelliteSize,
      width: satelliteSize,
      borderRadius: '50%',
    },
    svg: {
      height: satelliteSize,
      width: satelliteSize,
    },
    /**
     * ----------------------------------------------------
     * Animation: logo
     * ----------------------------------------------------
     */
    '@keyframes logo': {
      from: logoFrom,
      to: logoTo,
    },
    logo: {
      zIndex: 9999,
      position: 'absolute',
      animationName: '$logo',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '@keyframes logoReverse': {
      from: logoTo,
      to: logoFrom,
    },
    logoReverse: {
      zIndex: 9999,
      position: '$logoReverse',
      animationName: '$logo',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    /**
     * ----------------------------------------------------
     * Animation: northEast
     * ----------------------------------------------------
     */
    '@keyframes northEast': {
      from: from('-', distance, '+', distance / 3.5),
      to,
    },
    northEast: {
      animationName: '$northEast',
    },
    '@keyframes northEastReverse': {
      from: to,
      to: from('-', distance, '+', distance / 3.5),
    },
    northEastReverse: {
      animationName: '$northEastReverse',
    },
    /**
     * ----------------------------------------------------
     * Animation: east
     * ----------------------------------------------------
     */
    '@keyframes east': {
      from: from('-', satelliteSize / 2, '+', distance / 1.3),
      to,
    },
    east: {
      animationName: '$east',
    },
    '@keyframes eastReverse': {
      to: from('-', satelliteSize / 2, '+', distance / 1.3),
      from: to,
    },
    eastReverse: {
      animationName: '$eastReverse',
    },
    /**
     * ----------------------------------------------------
     * Animation: southEast
     * ----------------------------------------------------
     */
    '@keyframes southEast': {
      from: from('+', distance / 1.4, '+', distance / 3.5),
      to,
    },
    southEast: {
      animationName: '$southEast',
    },
    '@keyframes southEastReverse': {
      to: from('+', distance / 1.5, '+', distance / 3.5),
      from: to,
    },
    southEastReverse: {
      animationName: '$southEastReverse',
    },
    /**
     * ----------------------------------------------------
     * Animation: southWest
     * ----------------------------------------------------
     */
    '@keyframes southWest': {
      from: from('+', distance / 1.4, '-', distance / 2),
      to,
    },
    southWest: {
      animationName: '$southWest',
    },
    '@keyframes southWestReverse': {
      to: from('+', distance / 1.5, '-', distance / 2),
      from: to,
    },
    southWestReverse: {
      animationName: '$southWestReverse',
    },
    /**
     * ----------------------------------------------------
     * Animation: west
     * ----------------------------------------------------
     */
    '@keyframes west': {
      from: from('-', satelliteSize / 2, '-', distance),
      to,
    },
    west: {
      animationName: '$west',
    },
    '@keyframes westReverse': {
      to: from('-', satelliteSize / 2, '-', distance),
      from: to,
    },
    westReverse: {
      animationName: '$westReverse',
    },
    /**
     * ----------------------------------------------------
     * Animation: west
     * ----------------------------------------------------
     */
    '@keyframes northWest': {
      from: from('-', distance, '-', distance / 2),
      to,
    },
    northWest: {
      animationName: '$northWest',
    },
    '@keyframes northWestReverse': {
      to: from('-', distance, '-', distance / 2),
      from: to,
    },
    northWestReverse: {
      animationName: '$northWestReverse',
    },
  };
}

function OpeningAnimation(props) {
  const { reverse, classes } = props;

  return (
    <div className={classes.stage}>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          reverse ? classes.logoReverse : classes.logo,
        )}
      >
        <img alt="Logo" className={classes.img} src="/img/logo.svg" />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.northEastReverse : classes.northEast,
        )}
      >
        <img
          alt="Comodidade"
          className={classes.svg}
          src="/img/icons/greeting-animation/comodidade.svg"
        />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.eastReverse : classes.east,
        )}
      >
        <img
          alt="Participação"
          className={classes.svg}
          src="/img/icons/greeting-animation/participacao.svg"
        />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.southEastReverse : classes.southEast,
        )}
      >
        <img
          alt="Segurança"
          className={classes.svg}
          src="/img/icons/greeting-animation/seguranca.svg"
        />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.southWestReverse : classes.southWest,
        )}
      >
        <img
          alt="É simples arrecadar"
          className={classes.svg}
          src="/img/icons/greeting-animation/simples-arrecadar.svg"
        />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.westReverse : classes.west,
        )}
      >
        <img
          className={classes.svg}
          alt="Simples começar"
          src="/img/icons/greeting-animation/simples-comecar.svg"
        />
      </div>
      <div
        className={classNames(
          classes.animation,
          reverse && classes.reverse,
          classes.satellite,
          reverse ? classes.northWestReverse : classes.northWest,
        )}
      >
        <img
          className={classes.svg}
          alt="Transparência"
          src="/img/icons/greeting-animation/transparency.svg"
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(OpeningAnimation);
