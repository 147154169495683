import React from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import storage from '../../core/storage/actions';
import qstorage from '../../core/storage/queries';
import form from '../../core/components/form/actions';

function styles(theme) {
  const { white } = theme.palette.common;

  return {
    chip: {
      color: white,
      borderWidth: 2,
      fontWeight: 'bold',
      borderColor: white,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    deleteChip: {
      color: white,
      '&:hover': {
        color: '#ddd',
      },
    },
  };
}

function FilterActions(props) {
  const {
    onOpen,
    classes,
    onFilter,
    clearFilter,
    resetRowsIds,
    shouldReload,
    extraFilters,
    isUsingFilter,
    clearFilterForm,
  } = props;

  return (
    <>
      <Grow in={isUsingFilter} mountOnEnter unmountOnExit>
        <Chip
          color="primary"
          variant="outlined"
          onDelete={() => {
            clearFilter();
            clearFilterForm();
            resetRowsIds();
            if (shouldReload && onFilter) {
              if (extraFilters.size > 0) {
                onFilter(extraFilters);
              } else {
                onFilter(null);
              }
            }
          }}
          label="Limpar filtros"
          classes={{
            colorPrimary: classes.chip,
            deleteIconOutlinedColorPrimary: classes.deleteChip,
          }}
        />
      </Grow>
      <Grow in mountOnEnter unmountOnExit>
        <IconButton color="inherit" onClick={onOpen}>
          <FilterListIcon />
        </IconButton>
      </Grow>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const { view, formId = 'filterForm', table = 'table' } = ownProps;
  const filterKeyPath = [table, formId, 'submit'];
  const filters = qstorage.get(state, view, filterKeyPath, Map());
  return {
    isUsingFilter: filters.size > 0,
    shouldReload: filters.get('!@filters', List()).size > 0,
    extraFilters: qstorage.get(state, view, [table, 'datefilter'], List()),
    onFilter: qstorage.get(
      state,
      view,
      [table, formId, 'onFilter'],
      () => null,
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { view, formId = 'filterForm', table = 'table' } = ownProps;
  const filterKeyPath = [table, formId, 'submit'];
  const actions = {
    clearFilter: () => storage.set(view, filterKeyPath, Map()),
    clearFilterForm: () => form.clean(view, formId),
    resetRowsIds: () => storage.set(view, [table, formId, 'rowsId'], List([0])),
  };

  return bindActionCreators(actions, dispatch);
}

FilterActions.defaultProps = {
  onOpen: () => null,
  table: 'table',
  formId: 'filterForm',
};

FilterActions.propTypes = {
  /**
   * view em que os dados serão salvos.
   * Deve ser a mesma view passada para o `FilterForm`.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  view: PropTypes.string.isRequired,
  /**
   * Id da `FilterForm`.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  formId: PropTypes.string,
  /**
   * Id da `Table` em que os filtros serão usados.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  table: PropTypes.string,
  /**
   * Callback para abrir o `Dialog` do `FilterForm`.
   */
  onOpen: PropTypes.func,

  // * Props de estado
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  shouldReload: PropTypes.bool.isRequired,
  isUsingFilter: PropTypes.bool.isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearFilterForm: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

const StyledFilterActions = withStyles(styles)(FilterActions);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledFilterActions);
